
import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios.post("api/Lookup/AddSlideImage?MobileSlideURL="+ item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(response.data.data, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Lookup/MobileSliders")
        .then((response) => {
          commit('SET_MobileSlider', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.put("api/Lookup/UpdateMobileSlide", item)
        .then((response) => {
          commit('UPDATE_MobileSlider', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // UploadImage(context, image) {
  //   debugger;
  //   const formData = new FormData();
  //   formData.append("file",image, image.name);
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post("/API/Common/UploadImage", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data"
  //         }
  //       })
  //       .then(response => {
  //         if (response.status == 200) {
  //           resolve(response);
  //         }
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  UploadImage(context, image) {
    debugger;
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            debugger;
            resolve(response);
            axios.post("api/Common/saveSplashImage?imagPath="+ response.data)
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Lookup/DeleteMobileSlide?Id="+item.id)
        .then((response) => {
          commit('REMOVE_ITEM', item.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
