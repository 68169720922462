<template>
  <div id="page-user-edit">
    <vx-card>
 <div class="vx-row w-full">
    <div class="vx-row white-space  display: flex; align-items: center; justify-content: center; height: 100vh; /* Adjust this if the parent container is not the body */
margin: 0; /* Remove default margin */
 padding: 0; /* Remove ">
      <vs-card title="Upload Image">
        <h4 >{{ $t("ImageUrl") }}</h4>
        <template v-if="imageURL">
          <div class="img-container w-48 h-48 mx-auto flex items-center justify-center">
            <img :src="baseURL+imageURL" class="w-128 h-full object-contain" />
          </div>
        </template>
        <div class="modify-img flex justify-between mt-5">
          <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" />
          <vs-button v-if="checkPermission(تعديل) || CheckUser()" class="mr-4" type="flat" @click="$refs.updateImgInput.click()">
            <i class='bx bx-upload'></i> Update Image
          </vs-button>
          <vs-button v-if="checkPermission(حذف) || CheckUser()" type="flat" color="#999" @click="imageURL = null">
            <i class='bx bx-trash'></i> Remove Image
          </vs-button>
        </div>
      </vs-card>
    </div>
 </div>
 
</vx-card>

    <br>
    <div class="app" style="display:none">
      <carousel>
    <carousel-slide v-for="slide in MobileSliders" :key="slide.id" class="carousel-slider">
      <div class="image-container">
        <img :src="baseURL + slide.sliderPath" :alt="slide">
        <div class="icon-container">
          <vx-tooltip text="حذف">
            <div class="icon-background">
              <feather-icon
              v-if="checkPermission(حذف) || CheckUser()"
                icon="TrashIcon"
                class="trash-icon"
                @click.stop="openConfirm(slide)"
              />
            </div>
          </vx-tooltip>
        </div>
      </div>
    </carousel-slide>
  </carousel>
    </div>

  </div>
</template>

<script>
import VxCard from "../../../components/vx-card/VxCard.vue";
import { domain } from "@/gloabelConstant.js";
import moduleMobileSilder from "@/store/settings/MobileSlider/moduleMobileSlider.js";
import Carousel from './Carousel.vue';
import CarouselSlide from './CarouselSlide';

export default {
  components: {
   Carousel : Carousel,
   CarouselSlide : CarouselSlide,
   VxCard
  },
  data() {
    return {
      baseURL: domain,
      user_not_found: false,
        imageURL: "",

    };
  },
  computed: {
    MobileSliders() {
        debugger;
        return this.$store.state.MobileSliderList.MobileSliders;
      }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString(); // Adjust format as needed
    },
    CheckUser() {
      debugger;
          if (typeof localStorage !== 'undefined') {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
              return JSON.parse(userInfo).UserType === "SuperAdmin";
            }
          }
          return false; // Default return if localStorage or userInfo is not available
        },
      checkPermission(OPName) {
    const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];
    
    // Ensure Page and Page.Name are not null or undefined
    return screenRolesPermissions.some(permission => 
      permission.Operation && permission.Operation.Name === OPName
    );
  },
    addimages() {
        debugger;
        this.$store
          .dispatch("MobileSliderList/addItem", this.imageURL)
          .then(() => {
            debugger;
            window.showAddSuccess();
            this.initValues();
            this.$vs.loading.close();
            this.cancle();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showAddFailed(err.response.data.message);
            } else {
              window.showAddFailed("");
            }
            this.$vs.loading.close();
          });
          this.$store.dispatch("MobileSliderList/fetchDataListItems")

    },
    reset_data() {
      this.initValues();
    },

    initValues() {
      this.imageURL = "";
    },
    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });

   //   this.GetAllMobileSliders();
    },
    deleteData(data) {
      debugger;
      this.$store.dispatch("MobileSliderList/removeItem", data).catch((err) => {
        console.error(err);
      });
    },

    async updateCurrImg(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("MobileSliderList/UploadImage", file)
          .then((res) => {
            debugger;
            if (res.status == 200) {
              this.imageURL = res.data;
              this.successUpload();
              this.$vs.loading.close();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
   //   this.GetAllMobileSliders();
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Image uploaded Success",
      });
     // this.GetAllMobileSliders();
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!moduleMobileSilder.isRegistered) {
      this.$store.registerModule("MobileSliderList", moduleMobileSilder);
      moduleMobileSilder.isRegistered = true;
    }
    this.$store.dispatch("MobileSliderList/fetchDataListItems")

   // this.GetAllMobileSliders();

  },
  mounted() {
    //this.GetAllMobileSliders();
    this.isMounted = true;
  },
};
</script>


<style>
.card-heading,
.card-body {
  text-align: center;
  padding: 10px;
}
.card-footer {
  padding: 5px;
   background-color: #37bbbb;

}

.card-heading {
  background-color: #37bbbb;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: calc(50% - 100px);
  margin-bottom: 20px;
  background-color: whitesmoke;
  border: 1px solid #ddd;
  border-radius: 4px;
  grid-column: auto;
}

.image-container {
  padding: 10px;
    height: 100%;
  width: 100%;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 100%) {
  .card {
    width: 100%;
  }
}
.button-container {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 20px; /* Adds space between buttons */
}

.my-6 {
 padding: 10px 20px; /* Adjust padding as needed */
 font-size: 16px; /* Adjust font size as needed */
 background-color: #ffc107; /* Adjust background color as needed */
 border: none;
 cursor: pointer;
 transition: background-color 0.3s ease;
}

.my-6:hover {
 background-color: #e0a800; /* Adjust hover background color as needed */
}

.app {
        display:flex;
        justify-content: center;
        width: auto;
        height: auto;
    }
    .carousel {
        position:relative;
        overflow: hidden;
        width:800px;
        height:500px;
        z-index:10;
    }
    .btn {
        padding:5px 10px;
        background-color:rgba(0,0,0,0.5);
        border:1px solid transparent;
        margin:5px 10px;
        color:#FFF;
        height:50px;
        width:50px;
        position:absolute;
        margin-top:-25px;
        z-index:2;


    }
    .btn:hover {
        cursor: pointer;
    }
    .btn:focus{
        outline:none;
    }
    .btn-next {
        top:50%;
        right:0;
    }
    .btn-prev {
        top:50%;
        left:0;
    }
    .carousel-slider {
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        border-radius: 5%;
    }
    .carousel-slider img {
        width:100%;
        height:100%;
        border-radius: 1%;
    }
    .image-container {
  position: relative;
  display: inline-block;
}

.image-container {
  position: relative;
  display: inline-block;
}

.icon-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.icon-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}

.trash-icon {
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  color: currentColor; /* Ensure the icon inherits the current text color */
  cursor: pointer;
}

.trash-icon:hover {
  color: var(--primary-color); /* Replace with your primary color variable or value */
}
</style>
