
export default {
  ADD_ITEM(state, item) {
    state.MobileSliders.unshift(item);
  },
  SET_MobileSlider(state, MobileSliders) {
    state.MobileSliders = MobileSliders;
  },
  // UPDATE_MobileSlider(state, MobileSlider) {
  //   const MobileSliderIndex = state.MobileSliders.findIndex(
  //     p => p.ID == MobileSlider.ID
  //   );
  //   if (MobileSliderIndex != -1) {
  //     Object.assign(state.MobileSliders[MobileSliderIndex], MobileSlider);
  //   }
  // },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.MobileSliders.findIndex(p => p.ID == itemId);
    state.MobileSliders.splice(ItemIndex, 1);
  }
};
