<template>
  <div class="carousel" @keydown="checkSlide($event)" tabindex="0">
    <slot></slot>
    <button @click.prevent="next" class="btn btn-next">
      <i class="fa fa-angle-right"></i>
    </button>
    <button @click.prevent="prev" class="btn btn-prev">
      <i class="fa fa-angle-left"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      slides: [],
      slideDirection: '',
    };
  },
  computed: {
    slidesLength() {
      return this.slides.length;
    }
  },
  updated() {
    this.slides = this.$children;
    this.slides.forEach((slide, index) => {
      slide.index = index;
    });
  },
  methods: {
    next() {
      this.index++;
      if (this.index >= this.slidesLength) {
        this.index = 0;
      }
      this.slideDirection = 'slide-right';
    },
    prev() {
      this.index--;
      if (this.index < 0) {
        this.index = this.slidesLength - 1;
      }
      this.slideDirection = 'slide-left';
    },
    checkSlide(event) {
      if (event.keyCode === 39) {
        this.next();
      } else if (event.keyCode === 37) {
        this.prev();
      }
    }
  }
};
</script>

<style>
.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.btn {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.btn:focus {
  outline: none;
}

.btn-next {
  right: 10px;
}

.btn-prev {
  left: 10px;
}
</style>
