
import state from './moduleMobileSliderState.js'
import mutations from './moduleMobileSliderMutations.js'
import actions from './moduleMobileSliderActions.js'
import getters from './moduleMobileSliderGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
